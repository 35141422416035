let timer;

export default {
  scrollToElement(el, animate = true) {
    const element = document.getElementById(el);
    console.log({element, el});
    if (element) {
      element.scrollIntoView(animate ? {behavior: 'smooth'} : null);
      if (animate) setTimeout(() => {
        window.location.hash = el;
      }, 1000)
    }
  },
  debounce(func, timeout = 500) {
    clearTimeout(timer);
    return (...args) => {
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }
}
