<template>
  <div class="footer">
    <div class="footer-inner section-container">
      <div>
        <a href="/" style="margin-bottom: 10px">
          <img class="logo" src="/img/logo.svg" alt="">
        </a>
        <ul class="footer-menu">
          <li><a @click="methods.scrollToElement('constructor')">Конструктор</a></li>
          <li><a @click="methods.scrollToElement('works')">Наши работы</a></li>
          <li><a @click="methods.scrollToElement('delivery')">Доставка и оплата</a></li>
          <li><a href="https://shop.catanddogtag.ru/offer">Оферта</a></li>
        </ul>
      </div>
      <div>
        <ul class="contacts">
          <li v-html="info.work_time"></li>
          <li><a href="tel: +7 985 774 65 46">+7 985 774 65 46</a></li>
          <li><a href="mailto:info@steel-graver.ru">info@steel-graver.ru</a></li>
          <li>1-й Нагатинский пр., д. 14 оф.1</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {OrderInfo} from "@/api";
import methods from "@/methods";

export default {
  name: "TheFooter",
  props: {
    info: OrderInfo
  },
  data() {
    return {
      methods,
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  padding-top: 30px;
  padding-bottom: 30px;
  background: var(--dark-blue);

  &-inner {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 30px;

    .logo {
      width: 140px;
    }

    ul {
      list-style-type: none;

      li {
        font-family: 'Noto Sans';
        color: #FFFFFF;
        font-size: 18px;

        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }

    .footer-menu {
      list-style-type: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    &-inner {
      grid-template-columns: 1fr 1fr;
      row-gap: 40px;

      .logo {
        width: 100px;
      }

      ul {
        li {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
