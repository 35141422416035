<template>
  <div class="design"
       :class="{small, selected, 'bone': order.form_type === 'bone', 'circle': order.form_type !== 'bone'}">
    <img :src="`/img/${order.form_type}-${ colors[order.color]}.png`" alt=""
         class="design-form" :style="{opacity:small?0:1}">
    <img :src="designImg" alt="123"
         :style="side === 'front' ? `top: ${design.designTop || 0}px; transform: scale(${design.designScale || 0.97})` : 'color: black'"
         class="selected-design">

    <div v-if="side === 'front' && !designImg.includes('rounded')" class="design-text"
         :style="`inset: ${design.top || 0}px ${design.right || 0}px 0 ${design.left || 0}px;
                 transform: rotate(${design.rotate || 0}deg);
                 font-size: ${currentFont}px;
                 `"
         :class="font" ref="pet_name" id="pet_name">
      {{ order.pet_name }}
    </div>
    <CircleText
        v-else-if="side === 'front' && designImg.includes('rounded')"
        :text="order.pet_name.slice(0, design.maxNameLength)"
        :max-font-size="design.maxFontSize"
        :max-width="design.maxWidth"
        :font="font"
        :style="`top: ${design.top}px`"
    />
    <p class="design-text phone arial" v-else>
      <span>{{ order.pet_phone }}</span><br>
      <span v-if="order.pet_phone_2">{{ order.pet_phone_2 }}</span>
    </p>
  </div>
</template>

<script>
import CircleText from "@/components/CircleText";

export default {
  name: "Medal",
  components: {CircleText},
  props: ['order', 'side', 'colors', 'design', 'font', 'small', 'designImg', 'selected'],
  data() {
    return {
      currentFont: 16,
    }
  },
  mounted() {
    this.updateFontSize()
  },
  watch: {
    'order.pet_name'() {
      this.updateFontSize();
    },
    design() {
      this.updateFontSize();
    },
    side() {
      this.updateFontSize();
    },
  },
  methods: {
    updateFontSize() {
      setTimeout(() => {
        this.fontSize(
            this.design.maxWidth || 230,
            this.design.maxFontSize,
            size => this.currentFont = size
        );
      }, 200);
    },
    fontSize(maxWidth, maxFontSize, callback) {
      let ref = this.$refs.pet_name;
      if (ref && ref.clientWidth) {
        let currentWidth = ref.clientWidth;
        if (this.side === "front") {
          let fontSize = Number(this.currentFont) * maxWidth / currentWidth;
          if (fontSize < 16) fontSize = 16
          if (fontSize > maxFontSize) fontSize = maxFontSize
          callback(fontSize);
        } else {
          callback(28);
        }
      }
    },
  }
}
</script>
<style>
.design * {
  transition: all 100ms;
}
</style>
<style scoped lang="scss">
.design {
  overflow: hidden;
  width: fit-content;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  zoom: 1.5;

  .selected-design {
    width: 100%;
    position: absolute;
    transform: scale(0.95);
  }

  .design-text {
    position: absolute;
    margin: auto;
    width: fit-content;
    height: fit-content;
  }

  .phone {
    font-weight: bold;
    font-size: 24px;
  }
  &.bone .phone {
    font-size: 28px;
  }

  &.circle {

    &.small {
      border: 1px solid var(--dark-grey);
    }

    &.selected {
      border: 1px solid var(--primary-pink-color);
    }
  }

  &.bone {
    zoom: 1;
    height: auto;
  }

  &.small {
    cursor: pointer;
    //transform: scale(0.25);
    zoom: 0.33;

    &.bone {
      background: url("/img/bone_shape.png") no-repeat center center;
      background-size: cover;
      height: fit-content;

      &.selected {
        background: url("/img/bone_shape_selected.png") no-repeat center center;
        background-size: cover;
      }
    }

    &.circle {
      border-radius: 50%;
    }
  }
}
</style>
