<template>
  <div style="position: absolute;
    height: 100%;
    width: 220px;
    display: flex;
    justify-content: center;">
    <div ref="plainText" style="position: absolute;opacity: 0;" :style="`font-size: ${fontSize()}px;`"
         v-html="text.split('').map(l=>`<div style='width: fit-content;'>${l}</div>`).join('')"></div>
    <div style="display: block" class="circle-text" :class="font"
         ref="circle_text"
         :style="`font-size: ${fontSize()}px; width: ${text.length * letterWidth}px;display: ${hideText?'none':'block'}`"/>
  </div>
</template>

<script>
export default {
  name: "CircleText",
  props: ['text', 'maxWidth', 'maxFontSize', 'font'],
  data() {
    return {
      params: {radius: 87, dir: -1},
      lineHeight: 20,
      currentAngle: 0,
      letterWidth: 20,
      hideText: false,
      version: 0,
      html: '',
    }
  },
  mounted() {
    setTimeout(() => {
      this.recalcText(this.text);
    }, 100);
    setTimeout(() => {
      this.recalcText(this.text);
    }, 300);
  },
  watch: {
    text() {
      setTimeout(() => this.recalcText(this.text));
    }
  },
  computed: {
    // letters() {
    //   let letters = this.text.split('');
    //   letters = letters.map(letter => {
    //     let span = document.createElement('span')
    //     span.innerText = letter;
    //     return span;
    //   });
    //   this.layout(letters);
    //   let span = document.createElement('span');
    //   letters.forEach(letter => span.appendChild(letter));
    //   this.$refs.circle_text.innerHTML = this.text;
    //   this.setLetterWidth(span);
    //   return span.innerHTML;
    // },
  },
  methods: {
    recalcText(text) {
      let letters = text.split('');
      letters = letters.map(letter => {
        let span = document.createElement('span')
        span.innerText = letter;
        return span;
      });
      this.layout(letters);
      let span = document.createElement('span');
      letters.forEach(letter => span.appendChild(letter));
      this.hideText = true;
      this.$refs.circle_text.innerHTML = text;
      this.setLetterWidth(span);
      this.layout(letters);
      setTimeout(() => {
        this.$refs.circle_text.innerHTML = span.innerHTML;
        this.hideText = false;
      })
    },
    // updateCurvedText() {
    //   let radius = this.params.radius;
    //   let element = this.$refs.text1;
    //   var w = element.offsetWidth,
    //       h = element.offsetHeight;
    //
    //   element.style.minWidth = w + "px";
    //   element.style.minHeight = h + "px";
    //   element.style.bottom = "10px";
    //   element.style.position = "absolute";
    //   element.style.top = "auto";
    //   var html = "";
    //   var circleLength = 2 * Math.PI * radius;
    //   var angleRad = w / (2 * radius);
    //   var angle = 2 * angleRad * 180 / Math.PI / this.text.length;
    //   Array.from(this.text).forEach((letter, idx) => {
    //     html += `<span style="position: absolute;height:${radius}px;transformOrigin:bottom center;transform:translate(${w / 2}px,0px) rotate(${-idx * angle + this.text.length * angle / 2}deg)">${letter}</span>`;
    //   });
    //   console.log(html);
    //   element.innerHTML = html;
    // },
    setLetterWidth(letter) {
      let maxLetterWidthes = [];
      if (this.$refs.circle_text) {
        maxLetterWidthes = this.$refs.circle_text.querySelectorAll('span');
        maxLetterWidthes = maxLetterWidthes.length ? Array.from(maxLetterWidthes).map((span) => span.clientWidth) : [];
      }
      // console.log('maxLetterWidthes', maxLetterWidthes.join(', '));
      this.letterWidth = (maxLetterWidthes.length ? Math.max(...maxLetterWidthes) : 20);
    },
    fontSize() {
      let ref = this.$refs.circle_text;
      if (ref && ref.style) {
        const fontSize = 24 * this.maxWidth / (this.text.length * this.letterWidth);
        return Math.min(this.maxFontSize, fontSize) > 24 ? Math.min(this.maxFontSize, fontSize) : 24;
      } else {
        return 38;
      }
    },

    layout(letters) {
      const {radius, dir} = this.params;
      const originY = dir === -1 ? (-radius + this.lineHeight) : radius;
      const origin = `center ${originY / this.fontSize()}em`;
      const innerRadius = radius - this.lineHeight;
      let plainTextSpans = this.$refs.plainText.querySelectorAll('div');
      const {rotations, theta} = this.getLetterRotation(letters, innerRadius, plainTextSpans);
      this.currentAngle = theta;
      // console.log({plainTextSpans});
      letters.forEach((letter, index) => {
        const {style} = letter;
        const rotate = ((theta * -0.5) + rotations[index]) * dir;
        const translateX = ((plainTextSpans[index].clientWidth) * -0.5);
        console.log({translateX});
        const transform = `translateX(${translateX}px) rotate(${rotate}deg)`;
        style.position = 'absolute';
        style.bottom = dir === -1 ? 0 : 'auto';
        style.left = '50%';
        style.transform = transform;
        style.transformOrigin = origin;
        style.webkitTransform = transform;
        style.webkitTransformOrigin = origin;
      });

      if (this._forceHeight) {
        const height = theta > 180 ? this.sagitta(radius, theta) : this.sagitta(innerRadius, theta) + this.lineHeight;

        this.container.style.height = `${height / this.fontSize()}em`;
      }

      if (this._forceWidth) {
        const width = this.chord(radius, Math.min(180, theta));

        this.container.style.width = `${width / this.fontSize()}em`;
      }

      return this;
    },
    sagitta(r, theta) {
      return r * (1 - Math.cos(this.degreesToRadians(theta / 2)))
    },
    degreesToRadians(degrees) {
      return Math.PI / 180 * degrees;
    },
    radiansToDegrees(rad) {
      return 180 / Math.PI * rad;
    },
    getLetterRotation(metrics, r, plainTextSpans) {
      return metrics.reduce((data, {width}, index) => {
        const rotation = this.radiansToDegrees(plainTextSpans[index].clientWidth / r);

        return {
          theta: data.theta + rotation,
          rotations: data.rotations.concat([data.theta + (rotation / 2)]),
        };
      }, {theta: 0, rotations: []});
    },
    chord(r, theta) {
      return 2 * r * Math.sin(this.degreesToRadians(theta / 2))
    }
  }
}
</script>

<style scoped lang="scss">
.curved-text {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  font-size: 32px;
}

.curved-text span {
  min-width: 0.5em;
  text-align: center;
  padding: 30px;
  margin: 0px;
}

.circle-text {
  position: absolute;
  height: 100%;
}
</style>
