<template>
  <div class="video-player-backdrop" @click="$emit('on-close')">
    <div class="video-player-container">
      <img src="/public/img/icons/close.svg" alt="" class="close-btn" @click="$emit('on-close')">
      <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" frameBorder="0" width='100%' height="100%"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoPlayer",
  emits: ['on-close'],
}
</script>

<style scoped lang="scss">
.video-player-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  padding: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  .video-player-container {
    width: 635px;
    height: 420px;
    display: flex;
    flex-direction: column;
    .close-btn {
      width: 16px;
      align-self: flex-end;
      margin-right: -24px;
      margin-bottom: 8px;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 575px) {
    .video-player-container {
      width: 100%;
    }
  }
}
</style>