export class Api {
    static url = 'https://shop.catanddogtag.ru/';

    static request(method, _data) {
        let _method = method.includes('upload') ? `${method}` : `api/${method}`;
        let formData = new FormData();
        for (let i in _data) formData.set(i, _data[i]);
        return fetch(`${this.url}${_method}`, {
            method: "POST",
            headers: {
                Accept: 'application/json',
            },
            body: formData,
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText)
                }
                return response.json()
            })
            .then(data => {
                if (data.success) return data;
                alert(data.error);
                return Promise.reject(data);
            })
    }
}

export class OrderInfo {
    delivery_types;
    form_types;
    work_time;
}

export class Order {
    uuid;
    form_type;
    size;
    rope_color;
    color;
    pet_name;
    pet_phone;
    design_front;
    design_back;
    font;
    custom_design;
    client_name;
    client_phone;
    client_email;
    delivery_type;
    delivery_address;
    price;
    files;
    pet_phone_2;

    constructor(
        uuid,
        form_type,
        size,
        rope_color,
        color,
        pet_name,
        pet_phone,
        design_front,
        design_back,
        font,
        custom_design,
        client_name,
        client_phone,
        client_email,
        delivery_type,
        delivery_address,
        price,
        files,
        pet_phone_2,
    ) {
        this.uuid = uuid ??  '';
        this.form_type = form_type ?? 'circle';
        this.size = size ?? 'small';
        this.rope_color = rope_color ?? '';
        this.color = color ?? 0;
        this.pet_name = pet_phone ?? '';
        this.pet_phone = pet_phone ?? '';
        this.design_front = design_front ?? '/img/designs/0.png';
        this.design_back = design_back ?? '/img/designs/back-1.png';
        this.font = font ?? 'bookman-old-style';
        this.custom_design = custom_design ?? 0;
        this.client_name = client_name ?? '';
        this.client_phone = client_phone ?? '';
        this.client_email = client_email ?? '';
        this.delivery_type = delivery_type ?? 'pickup';
        this.delivery_address = delivery_address ?? '';
        this.price = price ?? 0;
        this.files = files ?? [];
        this.pet_phone_2 = pet_phone_2 ?? '';
    }
}

export const colors = ["melhior", "med", "latun"];
