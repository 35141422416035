const designs = {
  allDesigns: {
    circle: {
      front: [
        "/img/designs/0.png",
        "/img/designs/1.png",
        "/img/designs/2.png",
        "/img/designs/3.png",
        "/img/designs/4.png",
        "/img/designs/5.png",
        "/img/designs/6.png",
        "/img/designs/7.png",
        "/img/designs/8.png",
        "/img/designs/9.png",
        "/img/designs/10.png",
        "/img/designs/11.png",
        "/img/designs/12.png",
        "/img/designs/13.png",
        "/img/designs/14.png",
        "/img/designs/15.png",
        "/img/designs/16.png",
        "/img/designs/17.png",
        "/img/designs/18.png",
        "/img/designs/19.png",
        "/img/designs/20.png",
        "/img/designs/21.png",
        "/img/designs/22.png",
        "/img/designs/23.png",
        "/img/designs/24.png",
        "/img/designs/25.png",
        "/img/designs/26.png",
        "/img/designs/27_rounded.png",
        "/img/designs/28_rounded.png",
        "/img/designs/30_rounded.png",
        "/img/designs/31_rounded.png",
        "/img/designs/32_rounded.png",
      ],
      back: [
        "/img/designs/back-1.png",
        "/img/designs/back-2.png",
        "/img/designs/back-3.png",
        "/img/designs/back-4.png",
        "/img/designs/back-5.png",
      ]
    },
    bone: {
      front: ["/img/designs/bone-1.png", "/img/designs/bone-2.png"],
      back: ["/img/designs/bone-back.png"]
    }
  },
  designsDataMap: {
    "/img/designs/0.png": {
      fonts: ["bookman-old-style", "poetsen-one"],
      maxWidth: 180,
      maxFontSize: 130,
      maxNameLength: 12,
      top: -8
    },
    "/img/designs/1.png": {
      fonts: ["poetsen-one"],
      maxWidth: 170,
      maxFontSize: 42,
      maxNameLength: 12,
      top: 3
    },
    "/img/designs/2.png": {
      fonts: ["poetsen-one"],
      maxWidth: 180,
      maxFontSize: 87,
      maxNameLength: 12,
      top: -30,
      designScale: 0.92,
    },
    "/img/designs/3.png": {
      fonts: ["poetsen-one"],
      maxWidth: 130,
      maxFontSize: 130,
      maxNameLength: 8,
      top: -40,
      left: 14,
      designWidth: 210,
      designScale: 0.92,
    },
    "/img/designs/4.png": {
      fonts: ["comic-sans-ms"],
      maxWidth: 100,
      maxFontSize: 110,
      maxNameLength: 6,
      top: -18
    },
    "/img/designs/5.png": {
      fonts: ["bookman-old-style"],
      maxWidth: 122,
      maxFontSize: 90,
      maxNameLength: 8,
      top: 10, left: 52,
      designTop: -4, designScale: 0.95
    },
    "/img/designs/6.png": {
      fonts: ["poetsen-one"],
      maxWidth: 130,
      maxFontSize: 50,
      maxNameLength: 7,
      top: 63,
      right: 21
    },
    "/img/designs/7.png": {
      fonts: ["fulbo"],
      maxWidth: 140,
      maxFontSize: 90,
      maxNameLength: 9,
      top: -18,
      designScale: 0.93
    },
    "/img/designs/8.png": {
      fonts: ["comic-sans-ms"],
      maxWidth: 150,
      maxFontSize: 68,
      maxNameLength: 9,
      top: -20
    },
    "/img/designs/9.png": {
      fonts: ["fulbo"],
      maxWidth: 160,
      maxFontSize: 42,
      maxNameLength: 11,
      top: -22
    },
    "/img/designs/10.png": {
      fonts: ["fulbo"],
      maxWidth: 135,
      maxFontSize: 130,
      maxNameLength: 8,
      top: -18,
      designWidth: 211,
      designScale: 0.94
    },
    "/img/designs/11.png": {
      fonts: ["bookman-old-style"],
      maxWidth: 180,
      maxFontSize: 60,
      maxNameLength: 10,
      rotate: -27,
      top: 25,
      left: 17
    },
    "/img/designs/12.png": {
      fonts: ["poetsen-one"],
      maxWidth: 180,
      maxFontSize: 64,
      maxNameLength: 11,
      top: -64,
      designTop: -13
    },
    "/img/designs/13.png": {
      fonts: ["poetsen-one"],
      maxWidth: 180,
      maxFontSize: 70,
      maxNameLength: 9,
      top: -30
    },
    "/img/designs/14.png": {
      fonts: ["bookman-old-style"],
      maxWidth: 120,
      maxFontSize: 130,
      maxNameLength: 7,
      top: -18
    },
    "/img/designs/15.png": {
      fonts: ["poetsen-one"],
      maxWidth: 140,
      maxFontSize: 130,
      maxNameLength: 9,
      top: -18,
      designTop: -5
    },
    "/img/designs/16.png": {
      fonts: ["fulbo"],
      maxWidth: 125,
      maxFontSize: 130,
      maxNameLength: 7,
      top: -18,
      left: 72,
      designTop: -4,
      designScale: 0.93
    },
    "/img/designs/17.png": {
      fonts: ["fulbo"],
      maxWidth: 180,
      maxFontSize: 80,
      maxNameLength: 12,
      top: -20
    },
    "/img/designs/18.png": {
      fonts: ["bookman-old-style"],
      maxWidth: 95,
      maxFontSize: 90,
      maxNameLength: 5,
      top: -28,
      left: 15,
      designWidth: 210,
      designTop: -5,
      designScale: 0.90
    },
    "/img/designs/19.png": {
      fonts: ["comic-sans-ms"],
      maxWidth: 160,
      maxFontSize: 70,
      maxNameLength: 10,
      top: -50,
      designWidth: 212,
      designTop: -1,
      designScale: 0.92
    },
    "/img/designs/20.png": {
      fonts: ["comic-sans-ms"], maxWidth: 105, maxFontSize: 90, maxNameLength: 8, top: 90, designTop: -8,
      designScale: 0.91,
    },
    "/img/designs/21.png": {
      fonts: ["comic-sans-ms"],
      maxWidth: 130,
      maxFontSize: 58,
      maxNameLength: 8,
      top: -68,
      right: 38,
      designTop: -5,
    },
    "/img/designs/22.png": {fonts: ["poetsen-one"], maxWidth: 177, maxFontSize: 80, maxNameLength: 11, top: -30},
    "/img/designs/23.png": {
      fonts: ["poetsen-one"],
      maxWidth: 180,
      maxFontSize: 74,
      maxNameLength: 9,
      top: -10,
      designScale: 0.94
    },
    "/img/designs/24.png": {fonts: ["poetsen-one"], maxWidth: 140, maxFontSize: 70, maxNameLength: 9, top: 77},
    "/img/designs/25.png": {
      fonts: ["poetsen-one"],
      maxWidth: 125,
      maxFontSize: 58,
      maxNameLength: 6,
      left: 64,
      top: -45,
      designTop: -4,
      designScale: 0.94
    },
    "/img/designs/26.png": {fonts: ["fulbo"], maxWidth: 177, maxFontSize: 72, maxNameLength: 10, top: -30},

    "/img/designs/27_rounded.png": {
      fonts: ["poetsen-one"],
      maxWidth: 130,
      maxFontSize: 40,
      maxNameLength: 9,
      top: -10,
      radius: 80,
    },
    "/img/designs/28_rounded.png": {
      fonts: ["bookman-old-style"],
      maxWidth: 177,
      maxFontSize: 40,
      maxNameLength: 12,
      top: -20,
      radius: 40,
    },
    "/img/designs/30_rounded.png": {
      fonts: ["poetsen-one"],
      maxWidth: 177,
      maxFontSize: 40,
      maxNameLength: 11,
      top: -13,
      radius: 80,
    },
    "/img/designs/31_rounded.png": {
      fonts: ["poetsen-one"],
      maxWidth: 177,
      maxFontSize: 40,
      maxNameLength: 9,
      top: -10,
      radius: 80,
    },
    "/img/designs/32_rounded.png": {
      fonts: ["poetsen-one"],
      maxWidth: 177,
      maxFontSize: 40,
      maxNameLength: 9,
      top: -13,
      radius: 80,
    },

    "/img/designs/bone-1.png": {
      fonts: ["bookman-old-style", "comic-sans-ms", "fulbo"],
      maxWidth: 210,
      maxFontSize: 130,
      maxNameLength: 12
    },
    "/img/designs/bone-2.png": {
      fonts: ["bookman-old-style", "comic-sans-ms", "fulbo"],
      maxWidth: 210,
      maxFontSize: 130,
      maxNameLength: 12
    },
    "/img/designs/back-1.png": {fonts: ["arial"], maxWidth: 177, maxFontSize: 210, maxNameLength: 16, top: -18},
    "/img/designs/back-2.png": {fonts: ["arial"], maxWidth: 177, maxFontSize: 210, maxNameLength: 16, top: -40},
    "/img/designs/back-3.png": {
      fonts: ["arial"],
      maxWidth: 177,
      maxFontSize: 210,
      maxNameLength: 16,
      top: -18,
      designScale: 0.92,
    },
    "/img/designs/back-4.png": {fonts: ["arial"], maxWidth: 177, maxFontSize: 210, maxNameLength: 16, top: -25},
    "/img/designs/back-5.png": {fonts: ["arial"], maxWidth: 177, maxFontSize: 210, maxNameLength: 16},
    "/img/designs/bone-back.png": {fonts: ["arial"], maxWidth: 177, maxFontSize: 210, maxNameLength: 16}
  }
}

export default designs;
