<template>
  <div class="constructor-container section-container" id="constructor">
    <h1 class="constructor-title"> {{ isCheckout ? 'Оформление заказа' : 'Конструктор' }}</h1>
    <div class="constructor-inner" v-if="!isCheckout">
      <div class="fields-container">
        <div class="field-row form-and-size">
          <div class="field-label">Размер и форма</div>
          <div class="field-body">
            <div class="form-selector" :class="{'selected': order.form_type === 'circle' && order.size === 'small'}"
                 @click="selectForm('circle', 'small')">
              <div class="form-selector-label">25 мм</div>
              <img
                  :src="`/img/icons/form-circle${order.form_type === 'circle' && order.size === 'small' ? '-blue' : ''}.svg`"
                  alt="">
            </div>
            <div class="form-selector" @click="selectForm('circle', 'big')"
                 :class="{'selected': order.form_type === 'circle' && order.size === 'big'}">
              <div class="form-selector-label">32,6 мм</div>
              <img
                  :src="`/img/icons/form-circle${order.form_type === 'circle' && order.size === 'big' ? '-blue' : ''}.svg`"
                  alt="">
            </div>
            <div class="form-selector" @click="selectForm('bone', '')"
                 :class="{'selected': order.form_type === 'bone'}">
              <div class="form-selector-label">40x20 мм</div>
              <img :src="`/img/icons/form-bone${order.form_type === 'bone'? '-blue' : ''}.svg`" alt="">
            </div>
          </div>
        </div>
        <div class="field-row metal">
          <div class="field-label">Металл</div>
          <div class="field-body">
            <div
                @click="order.color = 0; updateOrder()"
                :class="{'selected': order.color === 0}" class="button melhior">Мельхиор
            </div>
            <div
                @click="order.color = 2; updateOrder()"
                :class="{'selected': order.color === 2}" class="button latun">Латунь
            </div>
            <div
                @click="order.color = 1; updateOrder()"
                :class="{'selected': order.color === 1}" class="button cuprum">Медь
            </div>
          </div>
        </div>
        <div class="field-row badge-inputs">
          <div class="input-container">
            <div class="field-label">Кличка питомца</div>
            <input class="input" v-model="order.pet_name" placeholder="Кличка" :maxlength="11">
          </div>
          <div class="input-container">
            <div class="field-label">Телефон</div>
            <input class="input" placeholder="+7 900 000 00 00" v-mask="'+7 ### ### ## ##'" v-model="order.pet_phone">
          </div>
        </div>
        <div class="field-row rope">
          <div class="checkbox-container">
            <input id="rope" type="checkbox" class="checkbox"
                   :checked="!!order.rope_color"
                   @change="order.rope_color ? order.rope_color = '' : order.rope_color = 'green'; updateOrder()">
            <label class="checkbox-label" for="rope">Добавить шнурок</label>
          </div>
          <div v-show="order.rope_color" class="field-body">
            <div class="vertical-radio-container" @click="order.rope_color = 'green'; updateOrder()">
              <input type="radio" v-model="order.rope_color" value="green">
              <img src="/img/ropes/rope_1.jpeg" alt="">
            </div>
            <div class="vertical-radio-container" @click="order.rope_color = 'black';updateOrder()">
              <input type="radio" v-model="order.rope_color" value="black">
              <img src="/img/ropes/rope_2.jpeg" alt="">
            </div>
            <div class="vertical-radio-container" @click="order.rope_color = 'red';updateOrder()">
              <input type="radio" v-model="order.rope_color" value="red">
              <img src="/img/ropes/rope_3.jpeg" alt="">
            </div>
          </div>
          <div v-show="order.rope_color" class="input-container" style="margin-top: 20px">
            <div class="field-label">Обхват шеи</div>
            <select style="min-width: 200px" v-model="order.neck_type" class="select" @change="updateOrder">
              <option v-for="(option, i) in info.neck_types" :value="i" :key="i">
                {{ option }}
              </option>
            </select>
          </div>
        </div>
        <div class="field-row second-phone">
          <div class="checkbox-container" @click="isSecondPhone = !isSecondPhone">
            <input type="checkbox" v-model="isSecondPhone" class="checkbox">
            <div class="checkbox-label">Добавить второй номер на обратную сторону</div>
          </div>
          <div v-show="isSecondPhone" class="field-body">
            <input class="input" placeholder="+7 900 000 00 00" v-mask="'+7 ### ### ## ##'" v-model="order.pet_phone_2">
          </div>
        </div>
        <div class="field-row price">
          <div class="field-label">Стоимость</div>
          <div class="price-value">{{ order.price }} ₽</div>
        </div>
      </div>
      <div class="design-selector-container">
        <div class="design-selector-header">
          <div v-show="order.custom_design === 0" class="side-selector">
            <div @click="side = 'front'" :class="{'active': side === 'front'}" class="side">Лицевая
            </div>
            <div @click="side = 'back'" :class="{'active': side === 'back'}" class="side">Оборотная
            </div>
          </div>
          <div :class="{'outlined': order.custom_design === 0, 'pink': order.custom_design === 1}"
               class="button custom-design-button"
               @click="order.custom_design = order.custom_design === 0 ? 1 : 0;updateOrder()">
            {{ order.custom_design === 0 ? 'Индивидуальный дизайн' : 'Конструктор' }}
          </div>
        </div>
        <div v-if="order.custom_design === 0" class="design-selector-body">
          <Medal :design-img="side === 'front' ? order.design_front : order.design_back" :font="order.font"
                 :order="order" :design="form_side_design[order.design_front]" :side="side" :colors="colors"/>
          <div class="all-designs-container" v-if="Object.keys(form_side_design).length > 1">
            <div class="all-designs-left disabled" @click="allDesignsScroll('left')" ref="all_designs_btn_left">
              <img src="/img/icons/arrow-left_dark.svg" alt="">
            </div>
            <div class="all-designs" ref="all_designs">
              <div class="design-selector-outer" v-for="(design, key) in form_side_design" :key="key"
                   v-if="order.pet_name.length < design.maxNameLength">
                <Medal :design-img="key"
                       :selected=" side === 'front' ? order.design_front === key && order.font === font : key === order.design_back"
                       :small="1" :font="font" v-for="(font, i) in design.fonts" @click.native="selectDesign(key, font)"
                       :side="side" :order="order" :colors="colors" :key="i" :design="design"
                />
              </div>
            </div>
            <div class="all-designs-right" @click="allDesignsScroll('right')" ref="all_designs_btn_right">
              <img src="/img/icons/arrow-right_dark.svg" alt="">
            </div>
          </div>
          <div :class="{'outlined': order.custom_design === 0, 'pink': order.custom_design === 1}"
               class="button custom-design-button custom-design-button--mobile"
               @click="changeDesignView">
            {{ order.custom_design === 0 ? 'Индивидуальный дизайн' : 'Конструктор' }}
          </div>
          <span class="design-hint">При обработке заказа мы подправляем размер шрифта, чтобы текст смотрелся гармонично с рисунком.</span>
        </div>
        <div class="individual-design-container" v-else id="custom-design">
          <div :class="{'outlined': order.custom_design === 0, 'pink': order.custom_design === 1}"
               class="button custom-design-button custom-design-button--mobile"
               @click="changeDesignView">
            {{ order.custom_design === 0 ? 'Индивидуальный дизайн' : 'Конструктор' }}
          </div>
          <div class="drag-and-drop-input-container" :class="{'drag-over': onDragOver}">
            <div class="drag-and-drop-input-label">Перетащите сюда файл с вашим изображением в форматах: jpeg, png,
              tiff, svg, ai, eps, rar, zip, pdf
            </div>
            <div class="files">
              <div class="file" v-for="(file, i) in fileNames" :key="file">
                <span class="file-name">{{ file }}</span>
                <img src="/img/icons/close.svg" alt="" class="delete-file-btn" @click="deleteFile(i)">
              </div>
            </div>
            <input type="file" accept="image/*,application/vnd.rar,application/zip,application/pdf" multiple
                   @change="uploadFiles" @dragover="onDragOver = true" @dragleave="onDragOver = false">
            <div class="button" :class="{'blue': onDragOver, 'outlined': !onDragOver}">Выбрать файл</div>
          </div>
          <div class="individual-design-hint">Мы сделаем адресник на основе вашего дизайна и пришлем на почту или
            WhatsApp для утвеждения. Эта услуга платная.
          </div>
        </div>
      </div>
    </div>
    <div class="checkout-inner" v-else>
      <div class="fields-container">
        <div class="field-row client-info">
          <div class="input-container">
            <div class="field-label">Ваше ФИО</div>
            <input class="input" v-model="order.client_name" placeholder="ФИО">
          </div>
          <div class="input-container">
            <div class="field-label">Ваш телефон</div>
            <input class="input" v-model="order.client_phone" placeholder="+7 900 000 00 00"
                   v-mask="'+7 ### ### ## ##'">
          </div>
          <div class="input-container">
            <div class="field-label">Ваш email</div>
            <input class="input" v-model="order.client_email" placeholder="email">
          </div>
        </div>
        <div class="field-row delivery flex-grow-1">
          <div class="input-container">
            <div class="field-label">Способ доставки</div>
            <select v-model="order.delivery_type" class="select" @change="updateOrder">
              <option v-for="option in info.delivery_types" :value="option.value" :key="option.type">
                {{ option.title }} - {{ option.price > 0 ? `${option.price} ₽` : 'Бесплатно' }}
              </option>
            </select>
          </div>
          <div class="input-container flex-grow-1">
            <div class="field-label">Адрес</div>
            <input class="input" v-model="order.delivery_address"
                   placeholder="г. Москва, 1-й Новокузнецкий переулок, дом 10с1, 115184" style="width: 100%">
          </div>
        </div>
        <div class="order-hint">
          <ul>
            <li>Срок изготовления адресника 1-3 рабочих дня</li>
            <li>После отправки заказа мы пришлем трек номер для отслеживания посылки</li>
          </ul>
        </div>
        <div class="field-row price">
          <div class="field-label">Стоимость</div>
          <div class="price-value">{{ order.price }} ₽</div>
        </div>
      </div>
      <div class="delivery-info">
        <p>При заказе <b>нескольких</b> адресников выбирайте способ доставки "С другим заказом" (бесплатно). Мы отправим
          их одной посылкой.</p>
      </div>
    </div>
    <div v-if="isCheckout" class="button pink order-btn checkout-btn" @click="proceed">Заказать</div>
    <div class="field-row price mobile-duplicate">
      <div class="field-label">Стоимость</div>
      <div class="price-value">{{ order.price }} <span>₽</span></div>
    </div>
    <div class="button pink"
         :class="{'pink': !isCheckout, 'outlined-pink': isCheckout, 'checkout-btn': !isCheckout}"
         @click="continueButton"> {{ isCheckout ? 'Вернуться к дизайну' : 'Оформить заказ' }}
    </div>
    <div class="delivery-button" @click="methods.scrollToElement('delivery')">Условия доставки и оплаты</div>
  </div>
</template>

<script>
import {Order, Api, OrderInfo} from "@/api";
import designs from "@/designs";
import methods from "@/methods";
import CircleText from "@/components/CircleText";
import Medal from "@/components/Medal";

export default {
  name: "Constructor",
  components: {Medal},

  props: {
    info: OrderInfo
  },
  data() {
    return {
      methods,
      designs,
      order: new Order(),
      isSecondPhone: false,
      side: 'front',
      colors: ['melhior', 'med', 'latun'],
      lastSelectedCircleDesign: {
        front: '/img/designs/0.png',
        back: '/img/designs/back-1.png',
        font: 'bookman-old-style',
      },
      lastSelectedBoneDesign: {
        front: '/img/designs/bone-1.png',
        back: '/img/designs/bone-back.png',
        font: 'bookman-old-style',
      },
      circleType: null,
      onDragOver: false,
      fileNames: [],
      isCheckout: false,
      requestNum: 0,
    }
  },
  methods: {
    continueButton() {
      this.isCheckout = !this.isCheckout;
      methods.scrollToElement('constructor', false);
    },
    changeDesignView() {
      this.order.custom_design = this.order.custom_design === 0 ? 1 : 0;
      setTimeout(() => {
        methods.scrollToElement('custom-design', false);
      }, 10);
      this.updateOrder();
    },
    getOrder() {
      let uuid = '';
      let isRepeat = false;
      if (window.location.search.includes('payment_fail')) {
        let url = window.location.href.split('/');
        let orderPart = url.indexOf('order');
        uuid = url[orderPart + 1].toString();
      } else if (localStorage.getItem('order_uuid')) {
        uuid = localStorage.getItem('order_uuid');
        isRepeat = true;
      } else {
        return;
      }
      Api.request(`order/get/${uuid}`).then((data) => {
        localStorage.setItem('order_uuid', '');
        let response = data.response;
        if (isRepeat) {
          response.delivery_type = 'another_order';
          response.pet_name = '';
        }
        this.order = this.dataFromResponse(response);
        setTimeout(() => {
          methods.scrollToElement('constructor');
        }, 1000)
      });
    },
    updateOrder(callback = null) {
      this.requestNum++;
      let currentNum = this.requestNum;
      let method = this.order.uuid ? `update/${this.order.uuid}` : 'create';
      Api.request(`order/${method}`, this.dataToRequest()).then((data) => {
        if (currentNum !== this.requestNum) return;
        this.order.price = data.response.price;
        this.order.uuid = data.response.uuid;
        if (callback) callback();
      });
    },
    dataToRequest() {
      let dataToRequest = {...this.order};
      if (this.order.form_type === 'circle') {
        dataToRequest.form_type = `${this.order.form_type}_${this.order.size}`;
      }
      return dataToRequest;
    },
    dataFromResponse(res) {
      const fromRes = {...res};
      if (res.form_type !== 'bone') {
        fromRes.form_type = res.form_type.split('_')[0];
        fromRes.size = res.form_type.split('_')[1];
      }
      if (res.pet_phone_2) this.isSecondPhone = true;
      return fromRes;
    },
    proceed() {
      this.updateOrder(() => {
        Api.request(`order/paymentLink/${this.order.uuid}`).then((data) => {
          location.href = data.response;
        })
      });
    },
    selectForm(form, size) {
      if (this.order.form_type !== form && this.order.size !== size) {
        if (form === 'circle') {
          this.order.design_front = this.lastSelectedCircleDesign.front;
          this.order.design_back = this.lastSelectedCircleDesign.back;
          this.order.font = this.lastSelectedCircleDesign.font;
        } else {
          this.order.design_front = this.lastSelectedBoneDesign.front;
          this.order.design_back = this.lastSelectedBoneDesign.back;
          this.order.font = this.lastSelectedBoneDesign.font;
        }
      }
      this.order.form_type = form;
      this.order.size = size;
      this.updateOrder();
    },
    selectDesign(design, font) {
      if (this.order.form_type === 'circle') {
        if (this.side === 'front') {
          this.lastSelectedCircleDesign.front = design;
          this.lastSelectedCircleDesign.font = font;

          this.order.design_front = this.lastSelectedCircleDesign.front;
          this.order.font = this.lastSelectedCircleDesign.font;
        } else {
          this.lastSelectedCircleDesign.back = design;
          this.order.design_back = this.lastSelectedCircleDesign.back;
        }
      } else {
        if (this.side === 'front') {
          this.lastSelectedBoneDesign.front = design;
          this.lastSelectedBoneDesign.font = font;

          this.order.design_front = this.lastSelectedBoneDesign.front;
          this.order.font = this.lastSelectedBoneDesign.font;
        } else {
          this.lastSelectedBoneDesign.back = design;
          this.order.design_back = this.lastSelectedBoneDesign.back;
        }
      }
      this.updateOrder();
    },
    isDesignSelected(key, font) {
      return this.side === 'front' ? this.order.design_front === key && this.order.font === font : this.order.design_back === key;
    },
    uploadFiles(e) {
      let files = e.target.files;
      for (let file of files) {
        Api.request('upload', {file}).then((data) => {
          if (!this.fileNames.includes(file.name)) {
            this.order.files.push(data.response);
            this.fileNames.push(file.name);
            this.updateOrder()
          }
        });
      }
    },
    deleteFile(i) {
      this.fileNames.splice(i, 1);
      this.order.files.splice(i, 1);
      this.updateOrder()
    },
    grabItems() {
      const slider = document.querySelector('.all-designs');
      let isDown = false;
      let startX;
      let scrollLeft;

      slider.addEventListener('mousedown', (e) => {
        isDown = true;
        slider.classList.add('active');
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener('mouseleave', () => {
        isDown = false;
        slider.classList.remove('active');
      });
      slider.addEventListener('mouseup', () => {
        isDown = false;
        slider.classList.remove('active');
      });
      slider.addEventListener('mousemove', (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
      });
    },
    allDesignsScroll(dir) {
      this.$refs.all_designs.scrollLeft += dir === 'left' ? -500 : 500;
      if (this.$refs.all_designs.scrollLeft === 0) {
        this.$refs.all_designs_btn_left.classList.add('disabled')
      } else if (this.$refs.all_designs.scrollLeft > 2200) {
        this.$refs.all_designs_btn_right.classList.add('disabled');
      } else {
        this.$refs.all_designs_btn_left.classList.remove('disabled');
        this.$refs.all_designs_btn_right.classList.remove('disabled');
      }
    }
  },
  computed: {
    form_side_design() {
      let current_designs = this.designs.allDesigns[this.order.form_type][this.side];
      return Object.fromEntries(Object.entries(this.designs.designsDataMap).filter(([key]) => current_designs.includes(key)));
    },
  },
  mounted() {
    if (window.location.search.includes('payment_fail') || localStorage.getItem('order_uuid')) {
      this.getOrder();
    } else {
      this.updateOrder();
    }
    this.grabItems();
  }
}
</script>

<style scoped lang="scss">
.constructor-container {
  margin-bottom: 100px;

  .constructor-title {
    margin-bottom: 40px;
  }

  .field-row {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .field-label {
      color: var(--dark-grey);
      font-size: 14px;
      margin-bottom: 8px;
    }
  }

  .constructor-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
    overflow: hidden;

    .fields-container {
      .form-and-size {
        .field-body {
          display: flex;
          column-gap: 20px;

          .form-selector {
            cursor: pointer;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            .form-selector-label {
              font-size: 14px;
              position: absolute;
              text-align: center;
            }

            img {
              height: 70px;
            }

            &.selected {
              .form-selector-label {
                color: #FFFFFF;
              }
            }
          }
        }
      }

      .metal {
        .field-body {
          display: flex;

          .button {
            opacity: .7;
            width: 100%;
            border: 2px solid #FFFFFF;

            &.selected {
              opacity: 1;
              border: 2px solid var(--dark-grey);
            }

            &:not(:last-child) {
              margin-right: 16px;
            }

            &.melhior {
              background: var(--grey);
            }

            &.cuprum {
              background: var(--orange);
            }

            &.latun {
              background: var(--yellow);
            }
          }
        }
      }

      .badge-inputs {
        display: flex;
        column-gap: 16px;

        input {
          width: 100%;
        }

        .input-container {
          flex-grow: 1;
        }
      }

      .rope {
        .field-body {
          margin-top: 20px;
          display: flex;
          column-gap: 32px;

          img {
            width: 64px;
            border-radius: 50%;
            object-fit: cover;
            display: block;
          }
        }
      }

      .second-phone {
        .field-body {
          margin-top: 20px;
        }
      }

      .price {
        &-value {
          font-size: 42px;
        }
      }
    }

    .design-selector-container {
      max-width: 555px;

      .design-selector-header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 40px;

        .side-selector {
          display: flex;
          align-items: flex-end;

          .side {
            padding: 0 10px 8px;
            color: var(--dark-grey);
            border-bottom: 2px solid var(--light-grey);
            cursor: pointer;

            &.active {
              color: var(--primary-text-color);
              border-bottom: 2px solid var(--primary-blue-color);
            }
          }
        }

        .custom-design-button {
          margin-left: auto;
        }
      }

      .custom-design-button--mobile {
        display: none;
      }

      .design-selector-body {
        overflow: hidden;

        .design-hint {
          color: var(--dark-grey);
          font-size: 14px;
        }

        .all-designs-container {
          display: flex;
          align-items: center;
          margin: 20px 0;

          .all-designs-left, .all-designs-right {
            padding: 0 10px;
            cursor: pointer;

            &.disabled {
              opacity: .5;
              cursor: default;
            }
          }

          .all-designs {
            height: 75px;
            display: flex;
            overflow-x: scroll;
            column-gap: 20px;
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
            &.active {
              cursor: grabbing;
              cursor: -webkit-grabbing;
            }

            &::-webkit-scrollbar {
              display: none;
            }

            .design-selector-outer {
              display: flex;
              column-gap: 20px;
              align-items: center;
              //transform: scale(0.25);

              .design-selector {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                .bone-shape {
                  position: absolute;
                }

                .design-text {
                  position: absolute;
                  margin: auto;
                  width: fit-content;
                  height: fit-content;
                }

                .phone {
                  font-size: 6px;
                  font-weight: bold;
                }
              }

              img {
                height: 64px;

              }
            }
          }
        }
      }
    }

    .individual-design-container {
      .drag-and-drop-input-container {
        border: 1px dashed var(--light-blue-color);
        border-radius: 26px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 40px;
        position: relative;
        margin-bottom: 20px;

        &.drag-over {
          border: 1px solid var(--primary-blue-color);
        }

        overflow: hidden;

        .drag-and-drop-input-label {
          text-align: center;
          margin-bottom: 8px;
        }

        .files {
          margin-bottom: 20px;
          z-index: 1;

          .file {
            display: flex;
            border-bottom: 1px solid var(--light-grey);
            padding-bottom: 4px;

            &:not(:last-child) {
              margin-bottom: 8px;
            }

            &-name {
              margin-right: 40px;
              flex-grow: 1;
            }

            .delete-file-btn {
              width: 12px;
              cursor: pointer;
            }
          }
        }

        input {
          opacity: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          inset: 0;
          cursor: pointer;
        }
      }

      .individual-design-hint {
        margin-bottom: 16px;
      }
    }
  }

  .price {
    font-size: 42px;

    &.mobile-duplicate {
      display: none;
    }
  }

  .checkout-inner {
    display: flex;
    column-gap: 50px;

    .fields-container {
      flex-basis: 66.6%;

      .client-info {
        display: flex;
        column-gap: 20px;
      }

      .delivery {
        display: flex;
        column-gap: 20px;
      }

      .price {
        margin-top: 20px;

        &-value {
          font-size: 42px;
        }
      }
    }

    .delivery-info {
      flex-basis: 33.3%;
      background: #eee;
      padding: 20px;
      border-radius: 10px;
      height: fit-content;

      &-title {
        font-family: "Noto Serif";
        font-size: 24px;
        margin-bottom: 20px;
      }

      ul {
        list-style-position: inside;
        padding-left: 16px;

        li {
          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  .order-btn {
    width: 200px;
    margin: 0 auto 20px;
  }

  .checkout-btn {
    width: fit-content;
    height: 73px;
    margin: 60px auto 0;
    box-shadow: 0px 11.451px 25.7647px rgba(245, 168, 205, 0.8);
    color: var(--dark-blue);
    font-size: 23px;
    font-weight: bold;
  }

  .delivery-button {
    margin-top: 26px;
    text-align: center;
    font-size: 16px;
    line-height: 15px;
    color: var(--dark-blue);
    font-weight: 500;
  }
}

@media screen and (max-width: 986px) {
  .constructor-container {
    .constructor-inner {
      display: block;

      .fields-container {
        margin-bottom: 40px;
        display: grid;
        grid-column-gap: 20px;
        grid-template-areas:
            'form color color'
            'inputs inputs inputs'
            'rope snd_phone .'
            'price . .';

        .form-and-size {
          grid-area: form;
        }

        .metal {
          grid-area: color;
        }

        .badge-inputs {
          grid-area: inputs;
        }

        .rope {
          grid-area: rope;
        }

        .second-phone {
          grid-area: snd_phone;
        }

        .price {
          grid-area: price;
        }
      }

      .design-selector-container {
        max-width: 100%;
      }
    }

    .checkout-inner {
      display: block;

      .fields-container {
        .client-info {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-column-gap: 30px;

          .input {
            width: 100%;
          }
        }
      }

      .delivery-info {
        //display: none;
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .constructor-container {
    margin-bottom: 40px;

    .constructor-inner {
      .fields-container {
        display: block;
        margin-bottom: 40px;

        .badge-inputs {
          display: block;

          .input-container {
            &:not(:last-child) {
              margin-bottom: 20px;
            }
          }
        }

        .second-phone {
          .field-body input {
            width: 100%;
          }
        }
      }

      .design-selector-container {
        .custom-design-button--mobile {
          display: flex;
          border: 2.5px solid var(--primary-pink-color);
          margin-bottom: 30px;
          color: var(--primary-pink-color);

          &.pink {
            color: #FFFFFF;
          }
        }
      }
    }

    .price.mobile-duplicate {
      display: block;
      width: fit-content;
      text-align: center;
      margin: 0 auto;

      .field-label {
        font-size: 18px;
        font-weight: 500;
        color: var(--grey);
      }

      span {
        color: var(--primary-blue-color);
        font-weight: normal;
      }
    }

    .checkout-inner {
      margin-bottom: 40px;

      .fields-container {
        .client-info {
          display: block;

          .input-container {
            &:not(:last-child) {
              margin-bottom: 20px;
            }
          }
        }

        .delivery {
          display: block;

          .input-container {
            .select {
              width: 100%;
            }

            &:not(:last-child) {
              margin-bottom: 20px;
            }
          }
        }
      }
    }

    .order-btn {
      max-width: 100%;
      width: 100%;
      margin-bottom: 20px;
    }

    .checkout-btn {
      margin-top: 20px;
      width: 100%;
    }

    .design-selector-header {
      flex-direction: column;
      align-items: start;
      gap: 20px;

      .side-selector {
        width: 100%;
        display: flex;

        .side {
          flex: 1;
        }
      }

      .custom-design-button {
        display: none;
      }
    }
  }
}
</style>
