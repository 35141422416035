<template>
  <div id="app" v-if="!success">
    <TheHeader :info="info" :is-menu="isMenu" @menu-toggle="menuToggle"/>
    <TheContainer :info="info" @menu-toggle="menuToggle"/>
    <TheFooter :info="info"/>
  </div>
  <div id="id" v-else>
    <Success/>
  </div>
</template>

<script>

import TheHeader from "/src/components/TheHeader";
import TheContainer from "/src/components/TheContainer";
import TheFooter from "/src/components/TheFooter";
import {Api, OrderInfo} from "@/api.js";
import '/src/styles.scss';
import '/public/img/icons/icons.scss';
import '/src/fonts.css';
import Success from "@/components/Success";
export default {
  name: 'App',
  components: {
    Success,
    TheFooter,
    TheContainer,
    TheHeader
  },
  data() {
    return {
      info: new OrderInfo(),
      success: window.location.href.split('/').includes('success'),
      isMenu: false,
    }
  },
  methods: {
    getInfo() {
      Api.request('order/info').then((data)=>{
        this.info = data.response;
      });
    },
    menuToggle(val) {
      this.isMenu = val;
    }
  },
  mounted() {
    this.getInfo();
  }
}
</script>

<style>

</style>
