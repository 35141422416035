<template>
  <div class="header">
    <div class="header-inner" @click="$emit('menu-toggle', false)">
      <a href="/">
        <img class="logo" src="/img/logo.svg" alt="">
      </a>
      <ul class="menu">
        <li><a @click="methods.scrollToElement('constructor')">Конструктор</a></li>
        <li><a @click="methods.scrollToElement('works')">Наши работы</a></li>
        <li><a @click="methods.scrollToElement('delivery')">Доставка и оплата</a></li>
      </ul>
      <div class="contacts">
        <a href="tel: +7 985 774 65 46" class="phone button pink">+7 985 774 65 46</a>
        <div class="work-time" v-html="info.work_time"/>
      </div>
      <img class="mobile-menu-button" src="/img/icons/burger.svg" alt="burger-menu" @click="menuBtnClick"/>
    </div>
    <div class="mobile-menu" v-if="isMenu">
      <div class="mobile-menu-close-button" @click="menuBtnClick">
        <img src="/img/icons/close_white.svg" alt="">
      </div>
      <ul class="menu">
        <li><a @click="methods.scrollToElement('constructor'); $emit('menu-toggle', false)">Конструктор</a></li>
        <li><a @click="methods.scrollToElement('works'); $emit('menu-toggle', false)">Наши работы</a></li>
        <li><a @click="methods.scrollToElement('delivery'); $emit('menu-toggle', false)">Доставка и оплата</a></li>
        <li class="work-time" v-html="info.work_time"/>
      </ul>
      <a href="tel: +7 985 774 65 46" class="phone button pink">+7 985 774 65 46</a>
    </div>
  </div>
</template>

<script>
import methods from "@/methods";
import {OrderInfo} from "@/api.js";

export default {
  name: "TheHeader",
  props: {
    info: OrderInfo,
    isMenu: Boolean,
  },
  emits: ['menu-toggle'],
  data() {
    return {
      methods,
    }
  },
  methods: {
    menuBtnClick() {
      setTimeout(() => {
        this.$emit('menu-toggle', !this.isMenu);
      })
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  height: 80px;
  padding: 0 20px;
  //background: var(--light-pink);
  &-inner {
    height: inherit;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      width: 140px;
    }

    .menu {
      list-style-type: none;
      display: flex;
      gap: 30px;
      font-size: 16px;

      li {
        //color: var(--primary-blue-color);
        font-family: 'Noto Sans';
        font-weight: 500;
      }

    }

    .contacts {
      display: flex;
      align-items: center;

      .phone {
        margin-right: 15px;
      }

      .work-time {
        color: var(--primary-blue-color);
        max-width: 200px;
        font-size: 14px;
      }
    }

    .mobile-menu-button {
      display: none;
    }
  }

  .mobile-menu {
    position: absolute;
    width: 100%;
    top: 0;
    height: 100vh;
    background: var(--primary-blue-color);
    padding: 90px 46px 60px;
    display: flex;
    flex-direction: column;
    left: 0;
    z-index: 999;

    .mobile-menu-close-button {
      position: absolute;
      top: 26px;
      left: 20px;
      z-index: 1000;
    }

    .phone.button {
      margin-top: auto;
    }

    .menu {
      list-style-type: none;

      li {
        color: #FFFFFF;
        font-size: 20px;

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }

      .work-time {
        margin-top: 20px;
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 986px) {
  .header {
    &-inner {
      .logo {
        width: 90px;
      }

      .menu {
        display: none;
      }

      .contacts {
        .work-time {
          display: none;
        }
      }

      .mobile-menu-button {
        display: block;
        z-index: 9;
      }
    }
  }
}

</style>
