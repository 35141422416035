<template>
  <div class="order-success section-container">
    <h1 class="order-success-title">Оформление заказа</h1>
    <div class="order-success-text">
      <div class="order-success-subtitle">Спасибо за ваш заказ!</div>
      <p>В ближайшее время менеджер свяжется с вами и уточнит все детали.</p>
    </div>
    <div class="order-success-actions">
      <div class="button blue" @click="orderMore">Заказать еще один</div>
      <div class="button outlined-pink" @click="goToMain">На главную</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Success",
  methods: {
    goToMain() {
      window.location.href = '/';
    },
    orderMore() {
        let currentHref = window.location.href.split('/');
        localStorage.setItem('order_uuid', currentHref[currentHref.length - 1]);
        window.location.href = '/#constructor';
    }
  }
}
</script>

<style scoped lang="scss">
.order-success {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 0 100px;
  &-text {
    text-align: center;
  }
  &-subtitle {
    font-size: var(--heading-1-size);
    color: var(--primary-pink-color);
    margin-bottom: 20px;
  }
  &-actions {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    :not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
</style>