<template>
  <div class="main-container" @click="$emit('menu-toggle', false)">
    <div class="hero-area section-container">
      <div class="description">
        <div class="description-title">Адресники<br>для домашних питомцев</div>
        <div class="description-text">Создать дизайн адресника вы можете в нашем конструкторе всего за несколько
          кликов
        </div>
        <a class="button blue create-design-button" @click="methods.scrollToElement('constructor')">Cоздать дизайн</a>
        <div class="dog-tag">
          <img src="/img/addressnik.png" alt="">
        </div>
      </div>
      <div class="img-container">
        <img class="img-dog" src="/img/dog.png" alt="">
        <img class="img-quality" src="/img/quality.png" alt="">
      </div>
    </div>
    <div class="metal-types-container section-container">
      <h1 class="metal-types-title">Гравируем адресники только на&nbsp;чистом металле</h1>
      <div class="metal-types">
        <img src="/img/melhior.png" alt="">
        <img src="/img/latun.png" alt="">
        <img src="/img/med.png" alt="">
      </div>
    </div>
    <div class="engraving-container section-container">
      <img class="img-cat" src="/img/cat.png" alt="">
      <div class="description">
        <div class="bg-circle"/>
        <div class="description-title">Адресник прослужит очень&nbsp;долго</div>
        <div class="description-text">Изображение наносится глубокой лазерной гравировкой, оно не сотрется и не
          потускнеет со временем
        </div>
        <div class="show-button" @click="isVideoPlayer = true">
          <img src="/img/icons/play.svg" alt="">
          Посмотреть процесс лазерной гравировки</div>
      </div>
    </div>
    <div class="works-container section-container" id="works">
      <h1 class="works-title">Наши работы</h1>
      <div class="swiper" ref="swiper">
        <div class="swiper-prev">
          <img src="/img/icons/arrow-left.svg" alt="">
        </div>
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(slide, i) in slides" :key="i">
            <img :src="`/img/works/${slide}`" alt="" width="150">
          </div>
        </div>
        <div class="swiper-next">
          <img src="/img/icons/arrow-right.svg" alt="">
        </div>
      </div>
    </div>
    <Constructor :info="info"/>
    <div class="delivery-container" id="delivery">
      <div class="delivery-inner section-container">
        <h1 class="delivery-title">Доставка и оплата</h1>
        <div class="info">
          <div class="info-text" style="margin-left: 20px">Срок изготовления адресника 1-3 рабочих дня</div>
          <div class="delivery-info">
            <ul data-v-5f6fb180="">
              <li data-v-5f6fb180=""> Отправка Почтой РФ - 250 ₽</li>
              <li data-v-5f6fb180=""> Отправка СДЭКом - 350 ₽</li>
              <li data-v-5f6fb180=""> Курьером по Москве (МКАД) - 500 ₽</li>
              <li data-v-5f6fb180=""><div style="display:inline-block;">Самовывоз - Бесплатно <br>(1-й Нагатинский пр., д. 14 оф.1)</div></li>
              <li data-v-5f6fb180=""> С другим заказом - Бесплатно</li>
            </ul>
          </div>
          <div class="pay-info">
            <img class="taksa-img" src="/img/taksa.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <VideoPlayer v-if="isVideoPlayer" @on-close="isVideoPlayer = false"/>
  </div>
</template>

<script>
import methods from "@/methods";
import {OrderInfo} from "@/api";
import Constructor from "@/components/Constructor";
import VideoPlayer from "@/components/VideoPlayer";

export default {
  name: "TheContainer",
  components: {
    VideoPlayer,
    Constructor
  },
  props: {
    info: OrderInfo,
  },
  emits: ['menu-toggle'],
  data() {
    return {
      methods,
      isVideoPlayer: false,
      slides: ['work_0.png', 'work_1.png', 'work_0.png', 'work_1.png', 'work_0.png', 'work_1.png', 'work_0.png', 'work_1.png', 'work_0.png', 'work_1.png', 'work_0.png', 'work_1.png',],
    }
  },
  methods: {
    initSwiper() {
      // eslint-disable-next-line no-undef
      const swiper = new Swiper('.swiper', {
        // Optional parameters
        loop: true,
        initialSlide: 0,
        centeredSlides: true,
        speed: 700,
        spaceBetween: 0,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 5,
          }
        },
        navigation: {
          prevEl: '.swiper-prev',
          nextEl: '.swiper-next',
        }
      });
      this.$refs.swiper.querySelector('.swiper-prev').addEventListener('click', () => {
        swiper.slidePrev()
      });
      this.$refs.swiper.querySelector('.swiper-next').addEventListener('click', () => {
        swiper.slideNext()
      });
    }
  },
  mounted() {
    this.initSwiper();
  }
}
</script>

<style scoped lang="scss">
.main-container {
  overflow: hidden;
  .hero-area {
    padding-top: 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
    margin-bottom: 60px;

    .description {
      position: relative;

      .dog-tag {
        position: absolute;
        right: 84px;
        bottom: -105px;
        border-radius: 50%;
        background: var(--primary-pink-color);
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 40px;
        width: 350px;
        height: 350px;

        img {
          width: 100px;
        }
      }

      &-title {
        position: relative;
        z-index: 1;
        font-family: "Noto Serif";
        font-size: 52px;
        line-height: 120%;
        margin-bottom: 30px;
        color: var(--primary-blue-color);
      }

      &-text {
        position: relative;
        z-index: 1;
        font-size: 24px;
        margin-bottom: 30px;
        max-width: 500px;
      }

      .create-design-button {
        max-width: 200px;
      }
    }

    .img-container {
      position: relative;

      .img-dog {
        width: 350px;
      }

      .img-quality {
        width: 100px;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }

  .metal-types-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;

    .metal-types-title {
      max-width: 800px;
      margin-bottom: 30px;
    }

    .metal-types-text {
      max-width: 800px;
      margin-bottom: 40px;
      font-size: 18px;
    }

    .metal-types {
      display: flex;
      column-gap: 50px;
    }
  }

  .engraving-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    margin-bottom: 60px;

    .img-cat {
      width: 500px;
    }

    .description {
      flex-basis: 50%;
      position: relative;
      .bg-circle {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 400px;
        height: 400px;
        border-radius: 50%;
        background: var(--primary-blue-color);
        z-index: 1;
      }
      &-title {
        z-index: 2;
        position: relative;
        font-family: "Noto Serif";
        font-size: 44px;
        line-height: 100%;
        margin-bottom: 30px;
        color: var(--primary-pink-color);
      }

      &-text {
        z-index: 2;
        position: relative;
        font-size: 21px;
        margin-bottom: 30px;
        max-width: 500px;
      }

      .show-button {
        width: 174px;
        height: 174px;
        border-radius: 50%;
        background: var(--primary-pink-color);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 2;
        position: relative;
        font-size: 14px;
        text-align: center;
        margin-left: auto;
      }
    }
  }

  .works-container {
    margin-bottom: 60px;

    .works-title {
      margin-bottom: 40px;
    }


    .swiper .swiper-slide img {
      animation: SlideSmall 400ms ease-in-out forwards alternate;
      -webkit-animation: SlideSmall 400ms ease-in-out forwards alternate;
      //box-shadow: 0 0 10px transparent;
      transition: box-shadow 400ms ease-in-out;
      border-radius: 50%;
    }
    .swiper .swiper-slide-active {
      z-index: 10;
    }
    .swiper .swiper-slide-active img {
      opacity: 1;
      animation: Slide 600ms ease-in-out forwards alternate;
      -webkit-animation: Slide 600ms ease-in-out forwards alternate;
      //box-shadow: 0 0 20px rgba(0, 0, 0, .4);
      border-radius: 50%;
      /*color: var(--light-grey)*/
    }

    .swiper .swiper-slide-active {
      padding: 0 40px;
    }

    @media screen and (max-width: 768px) {
      .swiper .swiper-slide-active {
        padding: 0;
      }
    }

    @keyframes Slide {
      0% {
        transform: scale(1);
        -webkit-transform: scale(1);
      }

      100% {
        transform: scale(2);
        -webkit-transform: scale(2);
      }
    }

    @keyframes SlideSmall {
      0% {
        transform: scale(1.8);
        -webkit-transform: scale(1.8);
      }

      100% {
        transform: scale(1);
        -webkit-transform: scale(1);
      }
    }


    .swiper {
      display: flex;
      position: relative;
      height: fit-content;
      align-items: center;

      .swiper-wrapper {
        height: 300px;
        align-items: center;
        width: calc(100% - 40px);

        .swiper-slide {
          display: flex;
          height: fit-content;
          justify-content: center;
        }
      }

      .swiper-next, .swiper-prev {
        min-width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        border-radius: 50%;
        background: var(--primary-pink-color);
        position: absolute;
        cursor: pointer;
        z-index: 10;
      }
      .swiper-next {
        right: 0;
      }
    }

  }

  .delivery-container {
    background: var(--light-pink);
    padding-top: 50px;
    padding-bottom: 50px;

    .delivery-inner {
      .delivery-title {
        margin-bottom: 40px;
      }

      .info {
        display: flex;
        column-gap: 30px;

        &-text {
          font-size: 18px;
          margin-bottom: 20px;
        }

        ul {
          padding-left: 20px;
          list-style-position: inside;

          li:not(:last-child) {
            margin-bottom: 8px;
          }
        }

        .delivery-info {
          ul {
            margin-bottom: 20px;
          }

          .info-text:last-child {
            margin-bottom: 0;
          }
        }

        .pay-info {
          display: flex;
          flex-direction: column;
          position: relative;

          .credit-cards {
            margin-left: auto;
            margin-top: auto;
            display: flex;
            column-gap: 10px;

            img {
              height: 20px;
            }
          }

          .taksa-img {
            width: 80%;
            border-radius: 50%;
            bottom: 40px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 968px) {
  .main-container {
    .hero-area {
      margin-bottom: 60px;
      padding-top: 40px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 30px;

      .description {
        .dog-tag {
          position: absolute;
          right: 0px;
          bottom: -60px;
          width: 225px;
          height: 225px;

          img {
            width: 70px;
          }
        }

        &-title {
          font-family: "Noto Serif";
          font-size: 35px;
          line-height: 120%;
          margin-bottom: 20px;
        }

        &-text {
          font-size: 13px;
          margin-bottom: 20px;
          max-width: 220px;
        }

        .create-design-button {
          border: 1px solid #FFFFFF;
          position: relative;
          z-index: 1;
        }
      }

      .img-container {
        position: relative;

        .img-dog {
          width: 250px;
        }

        .img-quality {
          width: 120px;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }

    .metal-types-container {
      margin-bottom: 60px;

      .metal-types-text {
        margin-bottom: 20px;
      }

      .metal-types {
        column-gap: 30px;

        img {
          width: 120px;
        }
      }
    }

    .engraving-container {
      margin-bottom: 60px;

      .img-cat {
        width: 250px;
      }

      .description {
        &-title {
          font-family: "Noto Serif";
          font-size: 36px;
          line-height: 120%;
          margin-bottom: 20px;
        }

        &-text {
          font-size: 18px;
          margin-bottom: 20px;
          max-width: 500px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .main-container {
    .delivery-container {
      .delivery-inner {
        .info {
          display: block;

          .pay-info {
            .taksa-img {
              right: 0;
              width: 80%;
              margin: auto;
              top: -20px;
            }

            .credit-cards {
              margin-left: 0;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }

}

@media screen and (max-width: 575px) {
  @keyframes Slide {
    0% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }

    100% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }

  @keyframes SlideSmall {
    0% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }

    100% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
  .main-container {
    .hero-area {
      padding-top: 0;
      margin-bottom: 40px;
      display: block;

      .description {
        margin-bottom: 20px;
        .dog-tag {
          position: absolute;
          right: -70px;
          top: 0;
          z-index: -1;
          bottom: 0;
          width: 250px;
          height: 250px;

          img {
            width: 100px;
            right: 70px;
            bottom: 20px;
            position: absolute;
          }
        }

        //.create-design-button {
        //  max-width: 100%;
        //}
      }

      .img-container {
        display: flex;

        .img-dog {
          width: 100%;
          margin: 0 auto;
        }

        .img-quality {
          right: 0;
        }
      }
    }

    .metal-types-container {
      margin-bottom: 35px;
      .metal-types {
        img {
          width: 80px;
        }
      }
    }

    .works-container .swiper .swiper-slide img {
      width: 284px;
    }

    .engraving-container {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 40px;
      gap: 0;

      .description {
        .bg-circle {
          top: 0;
          right: -40px;
          width: 300px;
          height: 300px;
        }
        .show-button {
          max-width: 100%;
        }
        &-text {
          margin-bottom: 0;
          font-size: 13px;
        }
      }

      .img-cat {
        width: 100%;
      }
    }

  }
}
</style>
